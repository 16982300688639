import {Injectable} from '@angular/core';
import {Breadcrumb, BreadcrumbConfig} from "./breadcrumb.component";
import {ZenIconsEnum} from "../../shared/_enums/zen-icons.enum";



@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    config: BreadcrumbConfig;

    hide: boolean;

    constructor() {
        this.hide = false;
    }

    setConfig(config: BreadcrumbConfig) {
        this.config = config;
    }

    clear() {
        this.config = null;
    }

    handleTitleClick() {
        if (this.config && this.config.command) {
            this.config?.command();
        }
    }

    onHide() {
        this.hide = true;
    }

    onShow() {
        this.hide = false;
    }

    handleBcClick(bc: Breadcrumb) {
        if (!bc.currentPage && !bc.disableClick) {
            bc.command();
        } else if (bc.icon === ZenIconsEnum.BACK) {
            this.handleTitleClick();
        }
    }

}
